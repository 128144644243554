import React, { useEffect } from 'react';

interface RedirectPageProps {
  targetUrl: string;
}

const RedirectPage: React.FC<RedirectPageProps> = ({ targetUrl }) => {
  useEffect(() => {
    // Immediately redirect to the target URL when the component mounts
    window.location.href = targetUrl;
  }, [targetUrl]);
  
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">STAR has been moved to a new location.</h1>
        <p className="text-gray-600 mb-6">
          You are being redirected to {targetUrl}. Please reach out to STAR administrators if you have any questions.
        </p>
        <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto"></div>
      </div>
    </div>
  );
};

export default RedirectPage;