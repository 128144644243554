import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import * as Okta from '@components/Okta'
import { Toaster } from '@components/Toaster'
import '@config/echarts'
import '@config/react-gtm-module'
import '@config/react-i18next'
import { queryClient } from '@config/react-query'
import { ThemeProvider } from '@contexts/Theme'
import OktaAuth from '@okta/okta-auth-js'
import ErrorBoundary from './pages/ErrorBoundary'
import { Routes } from './routes'
import RedirectPage from './pages/redirect' // Changed from named import to default import

const loginCallback = '/login/callback'

const okta = {
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID || '',
  redirectUri: window.location.origin + loginCallback,
  issuer: import.meta.env.VITE_OKTA_ISSUER || '',
  pkce: true,
  scopes: ['openid', 'profile', 'email'],
}
export const oktaAuth = new OktaAuth(okta)

// Get the redirect URL from environment variables
const redirectUrl = import.meta.env.VITE_WASS_REDIRECT_URL || ''

export default function App() {
  // If redirectUrl is set, show the redirect page
  if (redirectUrl) {
    return <RedirectPage targetUrl={redirectUrl} />
  }
  return (
    <HelmetProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Okta.AuthProvider
              oktaAuth={oktaAuth}
              oktaOptions={okta}
              onAuthRequired={() => {
                window.location.href = ''
              }}
            >
              <Toaster />
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Okta.AuthProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  )
}
